import { render, staticRenderFns } from "./MDetail.vue?vue&type=template&id=6183f1ec&scoped=true"
import script from "./MDetail.vue?vue&type=script&lang=js"
export * from "./MDetail.vue?vue&type=script&lang=js"
import style0 from "./MDetail.vue?vue&type=style&index=0&id=6183f1ec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_d4ca1f2c239379901a21149caec87237/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6183f1ec",
  null
  
)

export default component.exports