<template>
  <div class="evo-mtc-wrapper evo-mobile mx-auto">
    <div class="evo-breadcrumbs-wrapper">
      <v-breadcrumbs :items="breadcrumbs" class="evo-breadcrumbs">
        <template #divider>
          <v-icon>$vuetify.icons.mdiChevronRight</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <EvoMarkdown :content="md" />

    <div class="evo-hline"></div>
    <div class="evo-title evo-text-33 evo-opensans-regular">{{ $t('other_policies') }}</div>

    <v-row class="evo-other-policy">
      <v-col cols="12" v-for="(item, index) in items" :key="index">
        <router-link :to="item.link" target="_self" class="router-link">
          <div class="evo-item-wrapper">
            <div class="evo-item">
              <div class="evo-item-title evo-text-64 text-center mx-auto">{{ $t(item.desc) }}</div>
            </div>
            <div class="evo-item-desc evo-text-33">{{ $t(item.desc) }}</div>
          </div>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mtcDetailMixin } from '@/mixins/mtc.js';
export default {
  name: 'EvoMMTCDetailSection',
  mixins: [mtcDetailMixin]
};
</script>
<style lang="scss" scoped>
.evo-mtc-wrapper {
  .evo-breadcrumbs-wrapper {
    margin-top: 38px;
  }

  .evo-hline {
    margin: 16px 0 38px;
    height: 2px;
    background: #dbdbdb;
  }

  .evo-title {
    color: #222a41;
    margin-bottom: 28px;
    font-weight: bold;
  }

  ::v-deep(.evo-breadcrumbs) {
    padding-left: 0;
    padding-top: 0;

    .v-breadcrumbs__item {
      color: #222a41;
      font-size: 33px;
      font-family: 'OpenSans-Regular';
    }
  }

  .evo-other-policy {
    .evo-item-wrapper {
      box-shadow: 38px 38px 85px 0px rgba(0, 0, 0, 0.1);
      border-radius: 9px;
      background: #ffffff;
      cursor: pointer;
    }

    .evo-item {
      position: relative;
      background-image: url('~@/assets/mtc/mtc-bg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 24px 24px 0;
      height: 658px;

      .evo-item-title {
        margin-top: 230px;
        max-width: 600px;
        color: #ffffff;
      }
    }

    .evo-item-desc {
      padding: 0 60px 24px;
      color: #1a47b0;
    }
  }
}
</style>
