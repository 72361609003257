<template>
  <div>
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMMTCDetailSection />
    </template>
    <template v-else>
      <EvoMTCDetailSection />
    </template>
  </div>
</template>
<script>
import EvoMTCDetailSection from '@/components/mtc_sections/Detail.vue';
import EvoMMTCDetailSection from '@/mobile/components/mtc_sections/MDetail.vue';
export default {
  name: 'EvoPolicyDetailView',
  components: {
    EvoMTCDetailSection,
    EvoMMTCDetailSection
  }
};
</script>
